<template>
  <div class="bg-gray-50 p-5" :class="isOpen ? 'pb-0.5' : 'pb-5'">
    <div
      class="flex justify-between -mb-4"
      :class="{ 'border-b': isOpen }"
      @click="toggleOpen"
    >
      <lf-h3 class="pt-2 pb-6">
        {{ title || $t("DEALS.DEAL_PROGRESS.UNDERWRITING.SUBMITTED_DATA") }}
      </lf-h3>
      <dropdown-chevron class="transform translate-y-2-5" :isOpen="isOpen" />
    </div>
    <div class="my-5 pt-5" v-if="isOpen">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
defineProps({
  title: {
    type: String,
    default: ""
  }
});
const isOpen = ref(false);
const toggleOpen = () => (isOpen.value = !isOpen.value);
</script>
